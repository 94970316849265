import './App.css';

import React, {
  useEffect,
  useState,
} from 'react';

import { ethers } from 'ethers';

import gridImage8 from './assets/costv.png';
import gridImage6 from './assets/instagram.png';
import completedImage1 from './assets/ivy.png';
import gridImage1 from './assets/ivy.png';
import defaultCoverPhoto from './assets/kkkkaaapp.png';
import gridImage4 from './assets/linkedin.png';
import defaultProfilePhoto from './assets/profil.jpg';
import gridImage5 from './assets/telegram.png';
import gridImage7 from './assets/tiktok.png';
import gridImage9 from './assets/website.png';
import gridImage3 from './assets/x.png';
import completedImage2 from './assets/youtube.png';
import gridImage2 from './assets/youtube.png';
import PollHub from './pollhub.json';
import Token from './tokenali.json';

const COMMENT_INTERVAL = 4 * 0 * 60 * 1000; // 4 saat
const ChainRPC = 'https://bsc-dataseed.bnbchain.org';
const pollHubAddress = '0x6db6900b0D8BB700e957bD376CB20644C72871A9'; 
const tokenAddress = '0xB00052f9B6dF3C88c56451D54799c0848E0e3778'; 
const adminAddress = '0xcA2B2B11eb4a295E80B87BB0a5A07633Afe8f1f2'; 

function App() {
  const [activeProjects, setActiveProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [projectID, setProjectID] = useState('');
  const [Ilave, setIlave] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentAccount, setCurrentAccount] = useState('');
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]); // Yorumlar front-end üzerinde saklanacak
  const [adminMode, setAdminMode] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermCompleted, setSearchTermCompleted] = useState('');
  const [coverPhoto] = useState(defaultCoverPhoto);
  const [profilePhoto] = useState(defaultProfilePhoto);
  const [channelName, setChannelName] = useState('');
  const [editChannelName, setEditChannelName] = useState(false);
  const [yorumhavuzuBalance, setYorumhavuzuBalance] = useState(0); 
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [balanceError, setBalanceError] = useState(null);
  const [tokenDecimals, setTokenDecimals] = useState(6);
  const [totalBurned, setTotalBurned] = useState(0); 
  const [completedProjectPhotos, setCompletedProjectPhotos] = useState([]);
  const [grid1Link, setGrid1Link] = useState('');
  const [grid2Link, setGrid2Link] = useState('');
  const API_URL = 'https://kriptoofis.ivy.live/comments_api.php'; // PHP API adresiniz


  const fetchComments = async () => {
    try {
      const response = await fetch(API_URL); // PHP API'ye GET isteği
      const data = await response.json(); // JSON olarak gelen veriyi al
      setComments(data); // Yorumları state'e kaydet
    } catch (error) {
      console.error('Yorumlar alınırken hata oluştu:', error);
    }
  };
  
  useEffect(() => {
    fetchComments(); // Component yüklendiğinde yorumları çek
  }, []);
  


  const [currentPage, setCurrentPage] = useState(1);
  const commentsPerPage = 10;

  const gridLinks = [
    { img: gridImage1, link: 'https://ivy.live/kriptoofis' },
    { img: gridImage2, link: 'https://www.youtube.com/@kriptoofis' },
    { img: gridImage3, link: 'https://x.com/kriptoofis' },
    { img: gridImage4, link: 'https://www.linkedin.com/company/kripto-ofis/' },
    { img: gridImage5, link: 'https://t.me/KriptoOfisResmi' },
    { img: gridImage6, link: 'https://www.instagram.com/kriptoofis/' },
    { img: gridImage7, link: 'https://www.tiktok.com/@kriptoofis' },
    { img: gridImage8, link: 'https://cos.tv/channel/33324577691707392' },
    { img: gridImage9, link: 'https://www.kriptoofis.com/' },
  ];

  const getLastCommentTime = () => {
    if (!currentAccount) return null;
    const key = `lastCommentTime_${currentAccount.toLowerCase()}`;
    const time = localStorage.getItem(key);
    return time ? parseInt(time, 10) : null;
  };

  const setLastCommentTime = (time) => {
    if (!currentAccount) return;
    const key = `lastCommentTime_${currentAccount.toLowerCase()}`;
    localStorage.setItem(key, time.toString());
  };

  const getRemainingTimeInMinutes = () => {
    const lastTime = getLastCommentTime();
    if (!lastTime) return 0;
    const now = Date.now();
    const diff = COMMENT_INTERVAL - (now - lastTime);
    return diff > 0 ? Math.ceil(diff / 60000) : 0;
  };

  useEffect(() => {
    const fetchTokenDecimals = async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider(ChainRPC);
        const contractToken = new ethers.Contract(tokenAddress, Token.abi, provider);
        const decimals = await contractToken.decimals();
        setTokenDecimals(decimals);
      } catch (error) {
        console.error('Error fetching token decimals:', error);
      }
    };
    fetchTokenDecimals();
  }, []);

  const handleActiveProjects = async () => {
    setLoading(true);
    try {
      const provider = new ethers.providers.JsonRpcProvider(ChainRPC);
      const contract = new ethers.Contract(pollHubAddress, PollHub.abi, provider);
      const projects = await contract.getAllProjects();
      const projectsArray = projects.map((project) => ({
        blockchainID: project.id.toNumber(),
        Name: project.name,
        Vote: parseFloat(ethers.utils.formatUnits(project.vote, tokenDecimals)),
        voteInput: 0,
        link: '',
      }));
      projectsArray.sort((a, b) => b.Vote - a.Vote);
      setActiveProjects(projectsArray);
    } catch (error) {
      console.error('Aktif projeler alınırken hata oluştu:', error);
    }
    setLoading(false);
  };

  const handleCompletedProjects = async () => {
    setLoading(true);
    try {
      const provider = new ethers.providers.JsonRpcProvider(ChainRPC);
      const contract = new ethers.Contract(pollHubAddress, PollHub.abi, provider);
      const completedCount = await contract.getCompletedProjectsCount();
      const completedCountNum = completedCount.toNumber();
      const projects = [];
      for (let i = 1; i <= completedCountNum; i++) {
        try {
          const project = await contract.getCompletedProject(i);
          const projectData = {
            ID: project.id.toNumber(),
            Name: project.name,
            Link: project.link,
          };
          projects.push(projectData);
        } catch (err) {
          console.error(`Tamamlanmış proje ${i} alınırken hata oluştu:`, err);
          continue;
        }
      }
      projects.sort((a, b) => b.ID - a.ID);

      const completedPhotos = projects.map((projectData) => {
        const storedGrid1 = localStorage.getItem(`${projectData.ID}-grid1`) || '';
        const storedGrid2 = localStorage.getItem(`${projectData.ID}-grid2`) || '';
        return [
          { link: storedGrid1, isEditing: false },
          { link: storedGrid2, isEditing: false },
        ];
      });

      setCompletedProjects(projects);
      setCompletedProjectPhotos(completedPhotos);
    } catch (error) {
      console.error('Tamamlanmış projeler alınırken hata oluştu:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleActiveProjects();

    const listenForNewProjects = () => {
      const provider = new ethers.providers.JsonRpcProvider(ChainRPC);
      const contract = new ethers.Contract(pollHubAddress, PollHub.abi, provider);

      contract.on("ProjectAdded", (id, name, vote) => {
        console.log("Yeni proje eklendi:", id.toNumber(), name, vote.toNumber());
        handleActiveProjects();
      });

      contract.on("ProjectVoted", (id, vote) => {
        console.log("Proje oylandı:", id.toNumber(), vote.toNumber());
        handleActiveProjects();
      });

      contract.on("ProjectPublished", (id, link) => {
        console.log("Proje yayınlandı:", id.toNumber(), link);
        handleActiveProjects();
        handleCompletedProjects();
      });
    };

    listenForNewProjects();

    return () => {
      const provider = new ethers.providers.JsonRpcProvider(ChainRPC);
      const contract = new ethers.Contract(pollHubAddress, PollHub.abi, provider);
      contract.removeAllListeners("ProjectAdded");
      contract.removeAllListeners("ProjectVoted");
      contract.removeAllListeners("ProjectPublished");
    };
  }, [Ilave, tokenDecimals]);

  useEffect(() => {
    handleCompletedProjects();
  }, [Ilave, tokenDecimals]);

  useEffect(() => {
    const fetchYorumhavuzuBalance = async () => {
      setBalanceLoading(true);
      setBalanceError(null);
      try {
        const provider = new ethers.providers.JsonRpcProvider(ChainRPC);
        const contract = new ethers.Contract(pollHubAddress, PollHub.abi, provider);

        const balance = await contract.getYorumhavuzuBalance();
        const decimals = tokenDecimals;
        const formattedBalance = ethers.utils.formatUnits(balance, decimals);
        setYorumhavuzuBalance(parseFloat(formattedBalance));

        const totalBurnedRaw = await contract.getTotalBurned();
        const formattedBurned = ethers.utils.formatUnits(totalBurnedRaw, decimals);
        setTotalBurned(parseFloat(formattedBurned));
      } catch (error) {
        console.error('Yorumhavuzu bakiyesi alınırken hata oluştu:', error);
        setBalanceError('Yorum havuzu bakiyesi alınırken bir hata oluştu.');
      }
      setBalanceLoading(false);
    };

    fetchYorumhavuzuBalance();

    const interval = setInterval(() => {
      fetchYorumhavuzuBalance();
    }, 100000);

    return () => clearInterval(interval);
  }, [Ilave, tokenDecimals]);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const address = ethers.utils.getAddress(accounts[0]);
        setCurrentAccount(address);
        if (address.toLowerCase() === adminAddress.toLowerCase()) {
          setAdminMode(true);
        }
        console.log('Bağlı hesap:', address);
      } catch (error) {
        console.error('MetaMask bağlantısı sırasında hata oluştu', error);
      }
    } else {
      alert('Lütfen MetaMask kurun!');
    }
  };

  const disconnectWallet = () => {
    setCurrentAccount('');
    setAdminMode(false);
  };

  const inputVoteChanged = (evt, id) => {
    const value = evt.target.value;
    const updatedProjects = activeProjects.map((project) => {
      if (project.blockchainID === id) {
        return { ...project, voteInput: value };
      }
      return project;
    });
    setActiveProjects(updatedProjects);
  };

  const handleVoteKeyPress = (evt, id) => {
    if (evt.key === 'Enter') {
      addtoProject(id);
    }
  };

  const inputIDChanged = (evt) => {
    setProjectID(evt.target.value);
  };

  const addtoProject = async (id) => {
    setLoading(true);
    const project = activeProjects.find((proj) => proj.blockchainID === id);
    const voteAmount = parseFloat(project.voteInput) || 0;

    if (voteAmount < 10) {
      alert("En az 10 IVY oy vermelisiniz.");
      setLoading(false);
      return;
    }

    if (currentAccount) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(pollHubAddress, PollHub.abi, signer);
      const contractToken = new ethers.Contract(tokenAddress, Token.abi, signer);

      try {
        const decimals = await contractToken.decimals();
        const projectVoteID = ethers.utils.parseUnits(voteAmount.toString(), decimals);

        const allowance = await contractToken.allowance(currentAccount, pollHubAddress);
        if (allowance.lt(projectVoteID)) {
          const approveTx = await contractToken.approve(pollHubAddress, projectVoteID);
          await approveTx.wait();
        }

        const voteTx = await contract.addtoProject(id, projectVoteID);
        await voteTx.wait();

        setIlave(Ilave + 1);
        alert('Oy başarılı bir şekilde verildi!');
      } catch (error) {
        if (error.code === 4001) { 
          console.log('Kullanıcı işlemi reddetti.');
          alert('Oy verme işlemi reddedildi.');
        } else { 
          console.error('Proje oy ekleme sırasında hata oluştu', error);
          alert('Oy ekleme sırasında bir hata oluştu. Lütfen tekrar deneyin.');
        }
      }
    } else {
      alert('Lütfen önce cüzdanınızı bağlayın!');
    }
    setLoading(false);
  };

  const publishProject = async () => {
    const id = parseInt(projectID);
    if (!isNaN(id)) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(pollHubAddress, PollHub.abi, signer);
      try {
        const project = activeProjects.find((proj) => proj.blockchainID === id);
        if (!project) {
          alert("Geçersiz proje ID.");
          return;
        }

        const publishTx = await contract.publishProject(id, project.link || '');
        await publishTx.wait();

        const completedCount = await contract.getCompletedProjectsCount();
        const doneID = completedCount.toNumber();
        localStorage.setItem(`${doneID}-grid1`, grid1Link);
        localStorage.setItem(`${doneID}-grid2`, grid2Link);

        setIlave(Ilave + 1);
        setProjectID('');
        setGrid1Link('');
        setGrid2Link('');
        alert('Proje başarıyla yayınlandı!');
      } catch (error) {
        if (error.code === 4001) { 
          console.log('Kullanıcı işlemi reddetti.');
          alert('Proje yayınlama işlemi reddedildi.');
        } else { 
          console.error('Proje yayınlama sırasında hata oluştu', error);
          alert('Proje yayınlama sırasında bir hata oluştu. Lütfen tekrar deneyin.');
        }
      }
    } else {
      alert('Lütfen geçerli bir proje ID girin.');
    }
  };

  const addNewProject = async () => {
    if (newProjectName !== '') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(pollHubAddress, PollHub.abi, signer);

      try {
        const addTx = await contract.addProject(newProjectName);
        await addTx.wait();

        setIlave(Ilave + 1);
        setNewProjectName('');
        alert('Yeni proje başarıyla eklendi!');
      } catch (error) {
        if (error.code === 4001) { 
          console.log('Kullanıcı işlemi reddetti.');
          alert('Proje ekleme işlemi reddedildi.');
        } else { 
          console.error('Proje eklenirken hata oluştu:', error);
          alert('Proje eklenirken bir hata oluştu. Lütfen tekrar deneyin.');
        }
      }
    } else {
      alert('Lütfen proje adı girin.');
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleCommentKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleCommentSubmit();
    }
  };

  const handleCommentSubmit = async () => {
    if (!currentAccount) {
      alert('Lütfen cüzdanınızı bağlayın ve bir yorum yazın.');
      return;
    }
  
    if (!comment) {
      alert('Lütfen bir yorum yazın.');
      return;
    }
  
    const lastTime = getLastCommentTime();
    const now = Date.now();
    if (lastTime && (now - lastTime) < COMMENT_INTERVAL) {
      const remaining = getRemainingTimeInMinutes();
      alert(`Yorum yapabilmeniz için kalan süre: ${remaining} dakika`);
      return;
    }
  
    setLoading(true);
  
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(pollHubAddress, PollHub.abi, signer);
  
    try {
      // Blok zincirine yorumu ekleme
      const commentTx = await contract.addComment(comment);
      const txReceipt = await commentTx.wait();
  
      // CommentAdded eventini yakalayarak ödül miktarını bul
      let rewardValue = null;
      for (const ev of txReceipt.events) {
        if (ev.event === "CommentAdded") {
          rewardValue = ev.args.reward;
          break;
        }
      }
  
      let formattedReward = "0";
      if (rewardValue !== null) {
        formattedReward = ethers.utils.formatUnits(rewardValue, tokenDecimals);
      }
  
      // Yeni yorumu API'ye göndererek veri tabanına kaydetme
      const newComment = {
        author: currentAccount,
        text: comment,
        date: new Date().toLocaleString(),
        reward: `${formattedReward} IVY`, // Ödül miktarını buradan alıyoruz
      };
  
      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newComment),
        });
  
        const result = await response.json();
  
        if (!result.success) {
          console.error('API isteği sırasında hata oluştu:', result.message);
          alert('API isteği sırasında bir hata oluştu.');
        }
      } catch (apiError) {
        console.error('API isteği gönderilirken hata oluştu:', apiError);
      }
  
      // Frontend'de yeni yorumu gösterme
      setComments([newComment, ...comments]);
      setComment('');
      setLastCommentTime(Date.now());
      alert('Yorumunuz başarıyla gönderildi ve ödülünüz alındı!');
      setIlave(Ilave + 1);
      setCurrentPage(1);
    } catch (error) {
      console.error('Yorum işlemi sırasında hata oluştu:', error);
      if (error.code === 4001) {
        console.log('Kullanıcı işlemi reddetti.');
        alert('Yorum işlemi reddedildi.');
      } else if (error.data && error.data.message) {
        alert(`Yorum işlemi sırasında hata oluştu: ${error.data.message}`);
      } else {
        alert('Yorum işlemi sırasında bir hata oluştu. Lütfen tekrar deneyin.');
      }
    } finally {
      setLoading(false);
    }
  };
  

  const handleChannelNameChange = (event) => {
    setChannelName(event.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchChangeCompleted = (e) => {
    setSearchTermCompleted(e.target.value);
  };

  const indexOfLastComment = currentPage * commentsPerPage;
  const indexOfFirstComment = indexOfLastComment - commentsPerPage;
  const currentComments = comments.slice(indexOfFirstComment, indexOfLastComment);
  const totalPages = Math.ceil(comments.length / commentsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="App">
      {loading && (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}

      <div className="header">
        {!currentAccount ? (
          <button className="buttonConnect" onClick={connectWallet}>
            Cüzdanı Bağla
          </button>
        ) : (
          <>
            <div className="walletInfo">
              <p className="connectedAccount">Bağlı Hesap:</p>
              <p className="walletAddress">{currentAccount}</p>
              <button className="buttonDisconnect" onClick={disconnectWallet}>
                Cüzdanı Ayır
              </button>
            </div>
          </>
        )}
      </div>

      <div className="adminHeader">
        <div className="coverPhotoContainer">
          {coverPhoto && (
            <div className="coverPhotoWrapper">
              <img src={coverPhoto} alt="Cover" className="coverPhoto" />
              <div className="photoGrid">
                {gridLinks.map((item, index) => (
                  <div className="photoGridItem" key={index}>
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <img src={item.img} alt="Grid" className="gridPhoto" />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="profilePhotoContainer">
          {profilePhoto && (
            <div className="profilePhotoWrapper">
              <img src={profilePhoto} alt="Profile" className="profilePhoto" />
            </div>
          )}
        </div>
        <div className="channelNameContainer">
          {editChannelName ? (
            <input
              type="text"
              value={channelName}
              onChange={handleChannelNameChange}
              onBlur={() => setEditChannelName(false)}
              className="channelNameInput"
            />
          ) : (
            <h1 className="channelName" onClick={() => adminMode && setEditChannelName(true)}>
              {channelName || 'KRİPTO OFİS'}
            </h1>
          )}
        </div>
      </div>

      <div className="Icerik">
        <div className="KolonSOL">
          <div className="searchBox">
            <input
              type="text"
              placeholder="İsme göre ara"
              value={searchTerm}
              onChange={handleSearchChange}
              className="searchInput"
            />
          </div>
          <div className="tablex">
            <table className="table" id="table-1">
              <thead>
                <tr className="trx">
                  <th className="th">ID</th>
                  <th className="th">Ad</th>
                  <th className="th">İlerleme</th>
                  <th className="th">Oy Miktarı</th>
                  <th className="th">Oy Ver</th>
                  <th className="th">Toplam Oy</th>
                </tr>
              </thead>
              <tbody>
                {activeProjects
                  .filter(project => project.Name.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map((project) => (
                    <tr className="cikti" key={project.blockchainID}>
                      <td className="ortaIcerik">{project.blockchainID}</td>
                      <td className="ortaIcerik">{project.Name}</td>
                      <td>
                        <div className="progress-bar-container">
                          <div
                            className="progress-fill"
                            style={{
                              width: `${activeProjects.reduce((total, p) => total + p.Vote, 0) > 0 ? (project.Vote / activeProjects.reduce((total, p) => total + p.Vote, 0)) * 100 : 0}%`,
                            }}
                          ></div>
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          className="inputVote"
                          placeholder="Oy Miktarı"
                          value={project.voteInput}
                          onChange={(event) => inputVoteChanged(event, project.blockchainID)}
                          onKeyPress={(event) => handleVoteKeyPress(event, project.blockchainID)}
                          style={{ width: '80px' }}
                        />
                      </td>
                      <td>
                        <button
                          className="buttonC"
                          onClick={(event) => {
                            event.preventDefault();
                            addtoProject(project.blockchainID);
                          }}
                        >
                          Oy Ver
                        </button>
                      </td>
                      <td className="vauleTablo">{project.Vote}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="commentSectionContainer">
            <div className="commentSection">
              <div className="yorumhavuzuInfo">
                {balanceLoading ? (
                  <p>Güncelleniyor...</p>
                ) : balanceError ? (
                  <p className="error">{balanceError}</p>
                ) : (
                  <p>
                    <span className="yorumHavuzu">Yorum Havuzu 💬: {yorumhavuzuBalance.toFixed(2)} IVY</span> |{' '}
                    <span className="yakilanToplam">Toplam Yakılan IVY 🔥: {totalBurned.toFixed(2)} IVY </span>
                  </p>
                )}
              </div>
              <textarea
                className="commentBox"
                placeholder="Yorum yap ve yorum havuzundaki IVY Token'lerin %10'unu kazan ☘️"
                value={comment}
                onChange={handleCommentChange}
                onKeyPress={handleCommentKeyPress}
              ></textarea>
              <button className="buttonC" onClick={handleCommentSubmit}>
                Gönder
              </button>

              <div
  className="commentsList"
  style={{
    marginTop: '10px',
    padding: '5px'
  }}
>
  {comments.length === 0 ? ( // Eğer yorum yoksa
    <p>Henüz yorum yok.</p>
  ) : (
    comments.map((cmt, index) => ( // Yorumları listele
      <div key={index} className="comment">
        <p className="comment-author">
          <strong>{cmt.author}</strong>
        </p>
        <p className="comment-date">{cmt.date}</p>
        <p>{cmt.text}</p>
        <p>Ödül: {cmt.reward}</p>
      </div>
    ))
  )}
</div>

              {totalPages > 1 && (
                <div className="pagination">
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                    <button
                      key={pageNumber}
                      onClick={() => paginate(pageNumber)}
                      className={`page-button ${pageNumber === currentPage ? 'active' : ''}`}
                    >
                      {pageNumber}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="KolonSAG">
          <div className="searchBox">
            <input
              type="text"
              placeholder="İsme göre ara"
              value={searchTermCompleted}
              onChange={handleSearchChangeCompleted}
              className="searchInput"
            />
          </div>
          <div className="completedProjectsContainer">
            <p className="completedProjectsTitle">Tamamlanan Projeler</p>
            {completedProjects
              .filter(project => project.Name.toLowerCase().includes(searchTermCompleted.toLowerCase()))
              .map((project, projectIndex) => (
                <div className="completedProjectItem" key={project.ID}>
                  <div style={{ flex: 1 }}>
                    <span>{project.Name}</span>
                  </div>
                  <div className="completedProjectPhotos">
                    {completedProjectPhotos[projectIndex] && completedProjectPhotos[projectIndex].map((item, photoIndex) => {
                      const completedImage = photoIndex === 0 ? completedImage1 : completedImage2;
                      return (
                        <div className="photoGridItem" key={photoIndex}>
                          {item.link ? (
                            <a href={item.link} target="_blank" rel="noreferrer">
                              <img src={completedImage} alt="Grid" className="gridPhoto" />
                            </a>
                          ) : (
                            <img src={completedImage} alt="Grid" className="gridPhoto" />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
          </div>
          {adminMode && (
            <div className="adminSection">
              <h3 className="adminTitle">Admin Paneli</h3>
              <div className="publishProject">
                <h3>Proje Yayınla</h3>
                <input
                  type="number"
                  className="inputID"
                  placeholder="Proje ID"
                  value={projectID}
                  onChange={inputIDChanged}
                />
                <input
                  type="text"
                  className="inputName"
                  placeholder="Grid 1 Link"
                  value={grid1Link}
                  onChange={(e) => setGrid1Link(e.target.value)}
                />
                <input
                  type="text"
                  className="inputName"
                  placeholder="Grid 2 Link"
                  value={grid2Link}
                  onChange={(e) => setGrid2Link(e.target.value)}
                />
                <button className="buttonC" onClick={publishProject}>
                  Yayınla
                </button>
              </div>
              <div className="addProject">
                <h3>Yeni Proje Ekle</h3>
                <input
                  type="text"
                  className="inputName"
                  placeholder="Proje Adı"
                  value={newProjectName}
                  onChange={(e) => setNewProjectName(e.target.value)}
                />
                <button className="buttonC" onClick={addNewProject}>
                  Proje Ekle
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <footer className="footer">
        <div className="footerLinksContainer">
          <a href="https://www.ivylive.io/" target="_blank" rel="noreferrer" className="greenBox">ABOUT</a>
          <a href="https://dex.ivy.live/" target="_blank" rel="noreferrer" className="greenBox">BUY-SELL IVY</a>
          <a href="https://ivy.live/" target="_blank" rel="noreferrer" className="greenBox">IVY APP</a>
        </div>
      </footer>
    </div>
  );
}

export default App;
